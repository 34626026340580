<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>Perfil</strong>
        </CCardHeader>
        <CCardBody :items="this.item">
          <CForm @submit.prevent="atualizarUser" id="form">
            <CRow>
              <CCol col=12>
                <CAlert
                  :show.sync="dismissCountDown"
                  closeButton
                  color="danger"
                  fade
                >
                  {{errorMsg}}
                </CAlert>
              </CCol>
              <CCol col=6>
                <CInput
                  label="Nome"
                  v-model="item.username"
                  :value="this.item.username"
                  bottom
                  required
                  readonly
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="E-mail"
                  v-model="item.email"
                  :value="this.item.email"
                  bottom
                  type="email"
                  required
                  readonly
                />
              </CCol>

              <CCol col=6>
                <CInput
                  v-model="item.password"
                  :value="this.item.password"
                  label="Password"
                  bottom
                  type="password"
                  required
                />
              </CCol>
              
              <CCol col="12" class="text-center">
                <CButton type="submit" size="sm" color="primary">Salvar</CButton>
              </CCol>
            </CRow>
            
            
          </CForm>
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Users from "@/services/UserDataService";
export default {
  name: 'User',
  data: () => {
    return {
      item: '',
      id: 0,
      options: ['admin', 'moderator'],
      dismissCountDown: 0,
      errorMsg: ''
    }
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.id = this.currentUser.id
    this.getUserData();
    
  },
  methods: {
    atualizarUser(){
      Users.updateUser(this.id,this.item.username, this.item.email, this.item.password, this.item.roles).then(response => {
        
        if(response.data == 'ok'){
          let message = {
            status: 'success',
            text: '<strong>Perfil atualizado</strong>'
          }
          EventBus.$emit('showMessage', message);
          this.item.password = ''
        }else{
          this.errorMsg = response.data.message;
          this.dismissCountDown = '10';
        }
        return false
      })
      .catch(e => {
        alert(e);
      });
      return false
    },
    deleteUser(id){
      Users.delete(id).then(response => {

        if(response.data =='ok'){
          let message = {
            status: 'success',
            text: '<strong>Usuário removido</strong>'
          }
          EventBus.$emit('showMessage', message);
          this.$router.push('/users');
        }

      })
      .catch(e => {
        alert(e);
      });
    },
    getUserData () {
      
      Users.get(this.id)
        .then(response => {

          this.item = response.data;

        })
        .catch(e => {
          alert(e);
        });
    },
    goBack() {
      this.$router.push('/users');
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  }
}
</script>
